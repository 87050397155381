const Slogan = (props) => {
    let imageWidth = props.imageWidth || 55
    return (
        <div>
            <div className='flex items-center content-center gap-2'>
                <div className='text-center flex-grow-0 flex-shrink-0'>
                    <img src={props.img} className='w-full' alt='test' style={{ width: imageWidth }} />
                </div>
                <div className='slogan-text flex-grow'>
                    <div className='font-bold'>
                        {props.title}
                    </div>
                    <div className="text-sm">
                        {props.text}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Slogan